import { Localized, LocalizedString } from '@/helpers/LocaleHelper';

import i18n from '@/i18n';

import { MediaItem } from './Media';
import { RepairApiData, RepairApiSingleData } from './RepairInternal';

const { t, te } = i18n.global;

export type RepairSeverity = 1 | 2 | 3 | 4 | '1' | '2' | '3' | '4';

type OptionBase = {
  id: number;
  option: string;
  severity?: number;
  image?: string;
  next_question_id: number;
  repair_question_id: number;
};
export type RepairOption = OptionBase;
export type RepairCustomOption = OptionBase & {
  order: number;
  option_translatable: LocalizedString;
};

type QuestionBase = {
  id: number;
  question: string;
  description: string;
  media?: string | MediaItem;
  media_type?: string;
  repair_action_type: number;
  next_question_id?: number;
  prev_question_id?: number;
};
export type RepairQuestion = QuestionBase & {
  repair_options?: RepairOption[];
};
export type RepairCustomQuestion = QuestionBase & {
  repair_type_id?: number;
  repair_options?: RepairCustomOption[];
  question_translatable: LocalizedString;
  description_translatable: LocalizedString;
  severity?: RepairSeverity;
  media_translatable: Localized<string | MediaItem>;
  media_type_translatable: LocalizedString;
};

export const emptyQuestion: RepairCustomQuestion = {
  id: 0,
  question: '',
  description: '',
  question_translatable: {},
  description_translatable: {},
  media_translatable: {},
  media_type_translatable: {},
  repair_action_type: 4
};

type TypeBase = {
  id: number;
  name: string;
  image?: string;
};

export type RepairType = TypeBase & {
  repair_questions: RepairQuestion[];
};

export type RepairCustomType = TypeBase & {
  repair_questions: RepairCustomQuestion[];
  name_translatable: LocalizedString;
  order: number;
};

export type TypeApiResponse = {
  repair_types: (RepairType | RepairCustomType)[];
};
export type QuestionsApiResponse = {
  repair_questions: (RepairQuestion | RepairCustomQuestion)[];
};
export type RepairApiResponse = { repairs: RepairApiData[] };
export type RepairApiSingleResponse = { repair: RepairApiSingleData };

export const renderRepairSeverity = (severity: RepairSeverity) => t(`models.repair.severity.${severity}`);
export const renderAssignedTranslation = (index: number | string) => {
  if (te(`views.maintenance.assignment.overview.assigned.options.${index}`)) {
    return t(`views.maintenance.assignment.overview.assigned.options.${index}`);
  }

  return index!.toString();
};
