<template>
  <template v-if="buttonMode === 'router-link' && to">
    <router-link :is="disabled ? 'span' : 'router-link'" :to="to" active-class="" exact-active-class="">
      <button v-bind="$attrs" type="button" :class="buttonClasses" :disabled="disabled">
        <span v-if="icon && !spinner" :class="iconClasses">
          <img :class="iconImgClasses" :src="iconSrc" alt="" />
        </span>
        <ButtonSpinner v-if="spinner" />
        <template v-if="text">
          {{ text }}
        </template>
        <span v-if="iconTrailing && !spinner" :class="iconTrailingClasses">
          <img :class="iconImgClasses" :src="iconTrailingSrc" alt="" />
        </span>
      </button>
    </router-link>
  </template>

  <template v-else-if="buttonMode === 'link'">
    <a :href="href" :class="buttonClasses" target="_blank">
      <span v-if="icon && !spinner" :class="iconClasses">
        <img :class="iconImgClasses" :src="iconSrc" alt="" />
      </span>
      <template v-if="text">{{ text }}</template>
      <span v-if="iconTrailing && !spinner" :class="iconTrailingClasses">
        <img :class="iconImgClasses" :src="iconTrailingSrc" alt="" />
      </span>
    </a>
  </template>

  <template v-else-if="buttonMode === 'submit'">
    <button v-bind="$attrs" :type="disableSubmit ? 'button' : 'submit'" :class="buttonClasses" :disabled="disabled">
      <span v-if="icon && !spinner" :class="iconClasses">
        <img :class="iconImgClasses" :src="iconSrc" alt="" />
      </span>
      <ButtonSpinner v-if="spinner" />
      <template v-if="text">
        {{ text }}
      </template>
      <span v-if="iconTrailing && !spinner" :class="iconTrailingClasses">
        <img :class="iconImgClasses" :src="iconTrailingSrc" alt="" />
      </span>
    </button>
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { RouteLocationRaw as RouteLocation } from 'vue-router';

import ButtonSpinner from '@/components/static/ButtonSpinner.vue';

import useIcons from '@/helpers/AssetHelper';

import { ButtonColor, ButtonSize } from '@/globalComponents/Button';

const { icons } = useIcons('button');

interface SizeClassMap {
  [key: string]: string;
}

defineOptions({ inheritAttrs: false });

const props = withDefaults(
  defineProps<{
    text?: string;
    color?: string;
    size?: string;
    disableSubmit?: boolean;
    disabled?: boolean;
    spinner?: boolean;
    icon?: string;
    iconTrailing?: string;
    href?: string;
    to?: RouteLocation;
  }>(),
  {
    color: () => ButtonColor.BLUE,
    size: () => ButtonSize.MD
  }
);

const colorClassMap = ref<SizeClassMap>({
  [ButtonColor.GREEN]: 'text-white bg-green-500 border-green-500 hover:bg-green-400 focus:ring-green-400 transform active:translate-y-px',
  [ButtonColor.LIGHTBLUE]: 'text-blue-600 bg-blue-100 border-blue-500 hover:bg-blue-500 hover:border-blue-600 hover:text-white focus:ring-blue-400 transform active:translate-y-px',
  [ButtonColor.OUTLINEBLUE]: 'text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-blue-400 transform active:translate-y-px',
  [ButtonColor.BLUE]: 'text-white bg-blue-500 border-blue-500 hover:bg-blue-400 focus:ring-blue-400 transform active:translate-y-px',
  [ButtonColor.DARKBLUE]: 'text-white bg-darkblue-500 border-darkblue-500 hover:bg-darkblue-400 focus:ring-darkblue-400 transform active:translate-y-px',
  [ButtonColor.RED]: 'text-white bg-red-500 border-red-500 hover:bg-red-400 focus:ring-red-400 transform active:translate-y-px',
  [ButtonColor.PINK]: 'text-white bg-pink-500 border-pink-500 hover:bg-pink-400 focus:ring-pink-400 transform active:translate-y-px',
  [ButtonColor.YELLOW]: 'text-white bg-yellow-500 border-yellow-500 hover:bg-yellow-400 focus:ring-yellow-400 transform active:translate-y-px',
  [ButtonColor.GRAY]: 'text-white bg-gray-400 border-gray-400 hover:bg-gray-300 focus:ring-gray-300 transform active:translate-y-px',
  [ButtonColor.WHITE]:
    'text-gray-700 hover:text-gray-600 bg-white border-gray-300 focus:ring-gray-300 dark:bg-gray-500 dark:border-gray-500 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:border-gray-600 dark:hover:text-gray-200 transform active:translate-y-px'
});

const colorClasses = computed<string>(() =>
  props.disabled ? colorClassMap.value[props.color] + 'text-white cursor-not-allowed opacity-50' : colorClassMap.value[props.color] || ''
);

const sizeClassMap = ref<SizeClassMap>({
  [ButtonSize.XS]: 'py-0.5 px-2 text-xs font-light',
  [ButtonSize.SM]: 'py-1 px-3 text-sm',
  [ButtonSize.SMF]: 'w-full py-1 px-3',
  [ButtonSize.MD]: 'py-2 px-2 sm:py-2 sm:px-3 md:py-2 md:px-3',
  [ButtonSize.MDF]: 'w-full py-2 px-2 sm:py-2 sm:px-3 md:py-3 md:px-4',
  [ButtonSize.LG]: 'sm:py-3 py-3 px-3 sm:px-4 md:py-4 md:px-5',
  [ButtonSize.LGF]: 'w-full py-3 px-3 sm:py-3 sm:px-4 md:py-4 md:px-5'
});

const sizeClasses = computed<string>(() => sizeClassMap.value[props.size] || '');
const buttonClasses = computed<string>(() => 'button group relative ' + colorClasses.value + ' ' + sizeClasses.value);

const iconClasses = computed<string>(() => {
  if (props.size === ButtonSize.SMF || props.size === ButtonSize.MDF || props.size === ButtonSize.LGF) return 'absolute left-0 inset-y pl-4';
  else if (props.size === ButtonSize.XS) return 'mr-1';
  if (props.text) return 'mr-2';
  return '';
});

const iconTrailingClasses = computed<string>(() => {
  if (props.size === ButtonSize.SMF || props.size === ButtonSize.MDF || props.size === ButtonSize.LGF) return 'absolute right-0 inset-y pr-4';
  else if (props.size === ButtonSize.XS) return 'ml-1';
  if (props.text) return 'ml-2';
  return '';
});

const iconImgClasses = computed<string>(() => {
  if (props.size === ButtonSize.XS) return 'w-2 h-2 md:w-3 md:h-3';
  else if (props.size === ButtonSize.SMF || props.size === ButtonSize.SM) return 'w-3 h-3 md:w-3 md:h-3 my-1';
  else if (props.size === ButtonSize.MDF || props.size === ButtonSize.MD) return 'w-3 h-3 md:w-4 md:h-4';
  return 'w-4 h-4 md:w-5 md:h-5';
});

const buttonMode = computed<string>(() => {
  if (props.to) return 'router-link';
  else if (props.href) return 'link';
  else return 'submit';
});

const iconSrc = computed<string>(() => icons(`${props.icon}.svg`) || '');
const iconTrailingSrc = computed<string>(() => icons(`${props.iconTrailing}.svg`) || '');
</script>
