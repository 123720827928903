import { SelectOption } from '@/composables/Select';

import { Indexation } from '@/helpers/api/companyEnviorment/IndexationHelper';
import { LogisticalItem, LogisticalTypeId } from '@/helpers/api/companyEnviorment/LogisticalTypeHelper';
import { isBetween, newDateNull } from '@/helpers/javascript/DateHelper';

import i18n from '@/i18n';

import { ActivityApiData, AgreementActivityApiData } from './Activities';
import Invoice, { InvoiceApiData } from './Invoice';
import { HasMedia, MediaItem } from './Media';
import { PropertySingle as Property, PropertyApiSingleData } from './Property';
import { Space, SpaceApiData } from './Space';
import { TenantSingle as Tenant, TenantSingleApiData as TenantApiData } from './Tenant';

export type PaymentMethod = 'manual' | 'online_payment_platform';

export type AgreementLogisticalItem = {
  amount: number;
  description: string | null;
  logistical_item: LogisticalItem;
};

export enum IndexationCalculationModel {
  roz = 'roz_model',
  wssPoints = 'wws_points',
  businessIndexation = 'business_indexation',
  flatRate = 'flat_rate'
}

export function renderIndexationCalculationModel(model: IndexationCalculationModel) {
  return i18n.global.t(`models.indexation.calculationModel.${model}`);
}

export const getIndexationCalculationModelOptions = (): SelectOption<string>[] => {
  return [IndexationCalculationModel.roz, IndexationCalculationModel.wssPoints, IndexationCalculationModel.businessIndexation, IndexationCalculationModel.flatRate].map(
    (model) => ({
      key: model,
      text: renderIndexationCalculationModel(model)
    })
  );
};

export enum AgreementIndexationStatus {
  open = 'open',
  done = 'done',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
  disabled = 'disabled'
}

export function renderAgreementIndexationStatus(status: AgreementIndexationStatus) {
  return i18n.global.t(`models.agreement.indexationStatus.${status}`);
}

export class Agreement implements HasMedia {
  id: number;
  move_in: Date;
  move_out: Date | undefined;
  ended_at: Date | undefined;

  created_at: Date;
  tenant?: Tenant;
  residents?: Tenant[];
  private _property: Property | undefined;
  private _space: Space | undefined;
  payment_method?: PaymentMethod;
  invoices: Invoice[];
  media: MediaItem[];
  indexation_date: Date | undefined;
  indexation_deadline: Date | undefined;
  calculation_model: IndexationCalculationModel | null;
  min_indexation_percentage: string | null;
  max_indexation_percentage: string | null;
  first_indexation_year: Date | undefined;
  email_invoice: boolean;
  has_initial_invoice: boolean;
  invoice_day: number;
  invoice_period: string;
  next_invoice_day: Date | undefined;
  vat: number;
  logistical_items: LogisticalItem[];
  indexations: Indexation[];
  indexation_status: AgreementIndexationStatus;
  business: boolean;
  bank_guarantee_amount?: number;
  bank_guarantee_date?: Date;
  first_overdue_day: number;
  second_overdue_day: number;
  discount_ends_at: Date | null;
  deposit_chargeback_at?: Date | null;

  termination_period?: Date | null;
  termination_period_in_months?: number | null;

  break_option_at?: Date | null;
  break_option_type?: AgreementBreakOptionType;

  activities: AgreementActivityApiData[];

  get isActive(): boolean {
    const now = new Date();
    const start = this.move_in;
    const end = this.ended_at ?? this.move_out;
    if (!end) {
      return now.getTime() > start.getTime();
    }

    return isBetween({ d1: start, d2: end }, now);
  }

  get rent(): number | null {
    const agreementLogisticalItemRent = this.logistical_items?.find(({ logistical_type }) => logistical_type?.id === LogisticalTypeId.rent);
    if (!agreementLogisticalItemRent) return null;
    return agreementLogisticalItemRent.amount;
  }

  get status(): 'future' | 'active' | 'past' {
    if (this.isActive) {
      return 'active';
    }
    const now = new Date();
    if (this.move_in.getTime() > now.getTime()) {
      return 'future';
    }
    return 'past';
  }

  get property() {
    return this._property ?? this._space?.property;
  }

  get space() {
    return this._space;
  }

  get tenantName(): string {
    return this.tenant?.full_name ?? '';
  }

  get propertyAddress(): string {
    return this.property?.full_address ?? '';
  }

  get propertyValue(): Property | undefined {
    return this._property;
  }

  constructor(params: AgreementApiData) {
    this.id = params.id;
    // eslint-disable @typescript-eslint/naming-convention
    this.move_in = new Date(params.move_in);
    // eslint-disable @typescript-eslint/naming-convention
    this.move_out = newDateNull(params.move_out);
    // eslint-disable @typescript-eslint/naming-convention
    this.ended_at = newDateNull(params.ended_at);
    // eslint-disable @typescript-eslint/naming-convention
    this.created_at = new Date(params.created_at);
    // eslint-disable @typescript-eslint/naming-convention
    this.payment_method = params.payment_method;
    this.email_invoice = params.email_invoice;
    this.has_initial_invoice = params.has_initial_invoice;

    // eslint-disable @typescript-eslint/naming-convention
    this.indexation_date = newDateNull(params.indexation_date);
    // eslint-disable @typescript-eslint/naming-convention
    this.first_indexation_year = newDateNull(params.first_indexation_year);
    this.indexation_deadline = newDateNull(params.indexation_deadline);
    this.calculation_model = params.calculation_model;
    this.min_indexation_percentage = params.min_indexation_percentage;
    this.max_indexation_percentage = params.max_indexation_percentage;
    this.invoice_day = params.invoice_day;
    this.invoice_period = params.invoice_period;
    this.next_invoice_day = newDateNull(params.next_invoice_day);
    this.vat = params.vat;
    this.logistical_items = params.logistical_items;
    this.indexations = params.indexations;
    this.indexation_status = params.indexation_status;
    this.first_overdue_day = params.first_overdue_day;
    this.second_overdue_day = params.second_overdue_day;

    this.business = params.business ?? false;
    this.bank_guarantee_amount = params.bank_guarantee_amount ?? undefined;
    this.bank_guarantee_date = newDateNull(params.bank_guarantee_date);

    const { property, space, tenant, invoices, residents } = params;
    this._property = property ? new Property(property) : undefined;
    this._space = space ? new Space(space) : undefined;
    this.tenant = tenant ? new Tenant(tenant) : undefined;
    this.invoices = (invoices ?? []).map((data) => new Invoice(data));
    this.residents = (residents ?? []).map((data) => new Tenant(data));
    this.deposit_chargeback_at = params.deposit_chargeback_at ?? null;

    this.media = params.media ?? [];
    this.discount_ends_at = params.discount_ends_at ?? null;

    this.termination_period = params.termination_period ?? null;
    this.termination_period_in_months = params.termination_period_in_months ?? null;

    this.break_option_type = params.break_option_type;
    this.break_option_at = params.break_option_at ?? null;

    this.activities = params.activities ?? [];
  }
}

export enum AgreementBreakOptionType {
  COMPANY = 'company',
  TENANT = 'tenant',
  BOTH = 'both'
}

export type AgreementApiData = {
  id: number;
  move_in: string;
  move_out: string;
  ended_at: string | null;
  created_at: string;
  property?: PropertyApiSingleData;
  space?: SpaceApiData;
  tenant?: TenantApiData;
  payment_method?: PaymentMethod;
  invoices?: InvoiceApiData[];
  media?: MediaItem[];
  residents?: TenantApiData[];
  indexations: Indexation[];
  indexation_date: string | null;
  first_indexation_year: string | null;
  indexation_deadline?: string | null;
  calculation_model: IndexationCalculationModel | null;
  min_indexation_percentage: string | null;
  max_indexation_percentage: string | null;
  email_invoice: boolean;
  has_initial_invoice: boolean;
  invoice_day: number;
  invoice_period: string;
  next_invoice_day: Date | undefined;
  vat: number;
  logistical_items: LogisticalItem[];
  indexation_status: AgreementIndexationStatus;
  business: boolean | null;
  bank_guarantee_amount: number | null;
  bank_guarantee_date: string | null;

  first_overdue_day: number;
  second_overdue_day: number;

  deposit_chargeback_at: Date | null;
  discount_ends_at: Date | null;

  termination_period?: Date | null;
  termination_period_in_months?: number | null;

  break_option_at?: Date | null;
  break_option_type?: AgreementBreakOptionType;

  activities: AgreementActivityApiData[];
};

export type StoreAgreementLogisticalItem = {
  logistical_type_id: number;
  logistical_item_id: string;
  amount: number | null;
  description?: string;
  discount_percentage: number | null;
};

export type StoreAgreementForm = {
  move_in: Date;
  move_out?: Date;
  tenant_id?: number;
  co_tenant_ids: number[];
  resident_ids: number[];
  payment_method: 'online_payment_platform' | 'manual';
  email_invoice: boolean;
  send_email: boolean;
  generate_invoice_on_creation: boolean;
  property_id: string;
  space_id: string;
  move_out_preset: string;
  new_or_existing: string;
  indexation_date?: Date;
  first_indexation_year?: string;
  indexation_deadline: Date;
  calculation_model?: IndexationCalculationModel;
  min_indexation_percentage?: string;
  max_indexation_percentage?: string;

  invoice_day: string;
  invoice_period: string;

  logistical_items: StoreAgreementLogisticalItem[];
  business?: boolean;
  bank_guarantee_amount?: number;
  bank_guarantee_date?: Date;

  first_overdue_day: string;
  second_overdue_day: string;

  discount_ends_at?: Date | null;

  termination_period?: number | null;
  break_option_at?: Date | null;
  break_option_type?: AgreementBreakOptionType;
};

export type AgreementsApiResponse = { agreements: AgreementWithProperty[] };

export type AgreementProperty = {
  uuid: string;
  full_address: string;
  town: string;
};

export type AgreementWithProperty = {
  id: number;
  vat: string;
  service_cost: number;
  deposit: number;
  move_in: Date;
  move_out: Date;
  rent: number;
  tenant_id: number;
  co_tenant_ids: number[];
  resident_ids: number[];
  automatic_payments: boolean;
  send_email: boolean;
  property_id: AgreementProperty;
  property?: Property;
  space_id: string;
  space?: Space;
  indexation_date: Date;
  first_indexation_year: Date;
  deposit_chargeback_at: Date | null;
};

export default Agreement;
